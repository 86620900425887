.App {
  text-align: center;
}

.App-header {
  min-height: 87.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: 'black';
}

.App-header2 {
  min-height: 87.5vh;
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: 'black';
}

.App-link {
  color: #61dafb;
}

.Map {
  background-color: 'white';
  min-height: 80vh;
  font-size: calc(10px + 2vmin);
  color: 'black';
}

.Screen {
  align-items: "center";
  justify-content: "center";
  height: "100%";
  width: "100%";
  text-align: "center";
}